.bloc-ref{
	background-repeat: no-repeat;
	margin: 20px 0px 0px 0px;
	color:#fff;
	text-align: center;
	h2{
		font-size: 1.5rem;
		text-transform: uppercase;
		margin: 10px;
		font-weight: 200;
	}
	p{
		font-weight: 200;
		font-size: 0.9rem;
	}
	.btn-primary{
		color:#fff;
		font-size: 0.9rem;
		border-radius: 0;
		padding: 5px 50px;
		font-weight: 200;
	}
}
