.parallax{
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.heading-container{
  position: relative;
  width: 100%;
  overflow-x: hidden;
}

.heading-caption{
  position: absolute;
  text-align: center;
  top: 0%;
  width: 100%;
  left: -1%;
}
