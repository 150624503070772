footer{
  background-color: $secondary;
  color:#fff;
  padding: 20px 0px;
  text-align: center;
  h4{
    font-size: 1.2rem;
    font-weight: 200;
    text-transform: uppercase;
    text-align: center;
  }
  p{
    font-size: 0.8rem;
    font-weight: 200;
  }
  img{
   max-width: 70%;
   padding: 30px 0px
 }
 .btn-primary{
  border-radius: 0px;
  color:#fff;
  font-size: 0.8rem;
  }
}

.footer-links{
  padding:0;
}

.footer-links li {
  list-style: none;
}

.footer-links a{
  text-decoration: none;
  color:#fff;
  font-size: 0.8rem;
}
