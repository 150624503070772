.activite{

  a{
    color:#000;
    text-decoration: none;
  }

	img{
		width: 30%;
	}
	h2{
		text-transform: uppercase;
		font-size: 1rem;
		margin-top: 20px;
	}
	p{
		font-size: 0.8rem;
	}
	.bloc-activite{
    background-color: #fff;
    box-shadow: 0px 5px 30px 0px rgba(214, 215, 216, 0.57);
    border-color: transparent;
    padding: 30px;

    .bloc-img{
      background-repeat: no-repeat;
      height: 120px;
      background-position: center;
    }

    .bloc-img-elec{
      background-image: url('/assets/themes/front/img/electricite.png');

    }
    .bloc-img-automate{
      background-image: url('/assets/themes/front/img/automate.png');
    }
    .bloc-img-supervision{
      background-image: url('/assets/themes/front/img/supervision.png');
    }
  }

  .bloc-activite:hover{
    box-shadow: 0px 5px 30px 0px rgba(214, 215, 216, 0.57);
    border-color: transparent;
    transition: all .4s ease-in-out;
    color:#fff;

    a p, a h2 {
      color:#fff;
    }

  }

  .bloc-elec:hover{
    background-image: url('/assets/themes/front/img/elec-bg.png');


    .bloc-img-elec{
      background-image: url('/assets/themes/front/img/electricite-hover.png');
    }
  }
  .bloc-automate:hover{
    background-image: url('/assets/themes/front/img/automate-bg.png');

    .bloc-img-automate{
      background-image: url('/assets/themes/front/img/automate-hover.png');
    }
  }
  .bloc-supervision:hover{
    background-image: url('/assets/themes/front/img/supervision-bg.png');

    .bloc-img-supervision{
      background-image: url('/assets/themes/front/img/supervision-hover.png');
    }
  }
}
