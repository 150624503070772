.secteur-inter{
	h2{
		font-size: 1.5rem;
		text-transform: uppercase;
		margin: 20px;
		font-weight: 200;
		text-align: center;
	}
	h3{
		text-align: left;
		text-transform: uppercase;
		font-weight: 300;
		font-size: 1rem;
		margin-top: 10px;
	}
	p{
		text-align: left;
		font-size: 0.8rem;
		font-weight: 200;
	}
	img{
		width: 100%;
	}
	.btn-primary{
		width: 100%;
		color:#fff;
		font-size: 0.8rem;
		border-radius: 0;
		font-weight: 200;
    margin-bottom: 10px;
	}
}