.partenaires{
	margin: 20px 0px;
	h2{
		font-size: 1.5rem;
		text-transform: uppercase;
		margin: 20px;
		font-weight: 200;
		text-align: center;
	}
	img{
		max-width: 150px;
    margin-bottom: 20px;
	}
	.carousel-indicators {
		position: relative;
		margin-top: 20px;
	}
	.carousel-indicators li{
		background-color: $primary;
	}
	.carousel-indicators li.active{
		background-color: $secondary;
	}
}