.wrapper{
	width: 100vw;
	overflow: hidden;
  font-weight: 200;
  .slide{
    .heading-container{
     position: relative;
     width: 100%;
     overflow: hidden;
     @include media-breakpoint-up(xl){
      height: 90vh;
    }
    img{
      width: 100vw;
    }
  }
  .heading-caption{
    text-align: left;
    position: absolute;
    top: 15%;
    left: 10%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    @include media-breakpoint-up(md){
      top: 30%;
      left: 15%;
    }
    h1{
      font-size: 1.5rem;
      text-align: left;
      color:#000;
      font-weight: 200;
      @include media-breakpoint-up(md){
        font-size: 2.5rem;
      }
      @include media-breakpoint-up(xl){
        font-size: 4.5rem;
      }
    }
    .btn-primary{
      background-color: $primary;
      border-radius: 0;
      font-size:1rem;
      border:transparent;
      color:#fff;
      font-weight: 200;
      padding: 10px 50px;
      margin-top: 5px;
      @include media-breakpoint-up(md){
        margin-top: 20px;
      }
    }
    .btn-primary:hover{
      background-color: $secondary;
    }
    .btn-secondary{
      background-color: $secondary;
      border-radius: 0;
      font-size:1rem;
      font-weight: 200;
      padding: 10px 50px;
      border:transparent;
      margin-top: 5px;
      @include media-breakpoint-up(md){
        margin-top: 20px;
      }
    }
    .btn-secondary:hover{
      background-color: $primary;
    }
  }
}
}
